// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoiceFocusAudioWorkletNode = void 0;
class VoiceFocusAudioWorkletNode extends ((typeof globalThis !== 'undefined' && globalThis['AudioWorkletNode']) ||
    class Sadness {
    }) {
}
exports.VoiceFocusAudioWorkletNode = VoiceFocusAudioWorkletNode;
