"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = setGeolocation;

function setGeolocation(_ref) {
  var lat = _ref.lat,
      lon = _ref.lon;
  return "".concat(lat, ";").concat(lon);
}